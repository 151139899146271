export const awardsData = [
    {
      title: "Dr. Sherman Jen Award",
      symbol: "🏆",
      description:
        "The Dr. Sherman Jen award is a prestigious accolade that recognizes outstanding achievements in science. As a recipient of this esteemed award, I have demonstrated exceptional dedication to my field through research experiences, leadership activities, co-op placements, educational and teaching activities, and volunteer achievements. This honour not only highlights my commitment to academic excellence and community involvement but also bolsters my qualifications for the valedictorian position. Earning this award attests to my ability to effectively balance various responsibilities while maintaining an exceptional academic record, making me an exemplary candidate for the honour of valedictorian.",
    },
    {
      title: "Co-op Student of the Year",
      symbol: "🥇",
      description:
        "As a recipient of the annual Co-op Student of the Year award, I have showcased exceptional achievements in all aspects of my co-op experience, including academic excellence, work term learning, job achievement, employer evaluation, personal statement, and contribution to co-operative education. This prestigious honour demonstrates my dedication, hard work, and commitment to excellence throughout my co-op journey, as well as my active involvement in extra-curricular activities and the community. Winning this award highlights the value of co-op education in preparing students for success in their chosen fields, and it exemplifies my ability to excel.",
    },
    {
      title: "International Student Overall Excellence Award",
      symbol: "👨‍🎓",
      description: "As a distinguished recipient of the annual International Student Excellence and Impact Award, I have demonstrated extraordinary achievements in both academic excellence and community impact. This prestigious recognition highlights my commitment to global citizenship, inclusivity, and active involvement in community initiatives. The award celebrates my contributions to creating a vibrant and diverse community, setting a benchmark for academic and social engagement. Winning this award underscores the value of active global participation in education, exemplifying my ability to excel beyond classroom boundaries."
    }
  ];