// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import myPhoto from '../Visuals/Logo.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul>
      <li>
          <Link to="/">
            <img src={myPhoto} alt="Kulraj Sandhu Logo" />
          </Link>
        </li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/awards">Awards</Link></li>
        <li><Link to="/experiences">Experiences</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
