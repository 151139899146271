export const experiences = [
    {
      company: "Aurora UAV Services",
      title: "Machine Learning Engineer - Remote Sensing",
      period: "July 2023 - October 2023",
      description:
        "As a key team member in an innovative environmental project, I am responsible for detecting invasive CheatGrass using drone-derived multispectral and LiDAR data. My role involves converting this data into environmental and climate variables for geospatial insights, and utilizing machine learning techniques like Random Forest and Support Vector Machines for predictive analysis. I also conduct geospatial data analysis and work with Digital Elevation Models (DEMs), applying my technical expertise and attention to detail to provide valuable insights in combating environmental challenges."
    },
    {
      company: "Thompson Rivers University",
      title: "Teaching Assistant",
      period: "January 2023 – April 2023",
      description:
        "As a Teaching Assistant in the Software Engineering department, I support the teaching of two courses, Communications Networks and Safety Critical Software Systems. In this role, I am responsible for completing and testing labs in advance, as well as grading lab assignments for both courses. My attention to detail and technical proficiency enables me to provide valuable support to students and instructors alike.",
    },
    {
      company: "Thompson Rivers University",
      title: "Future Student Ambassador",
      period: "January 2023 – April 2023",
      description:
        "I represent my university and its values to prospective students and visitors by lead campus tours, showcasing the facilities and resources available to students. I also do social media takeovers to share my experiences and insights with followers. I will also be a part in organizing the 2023 Spring Open House for future students.",
    },
    {
      company: "Thompson Rivers University",
      title: "Student Innovator",
      period: "October 2022 – April 2023",
      description:
        "As an active member of the TRU Student Research Department, I have gained valuable experience in various research methodologies applicable to both academic and community-centric research. My focus has been on studying the effects of research activities on an individual's emotional intelligence. Additionally, I have contributed to the design of the new student research hub at TRU.",
    },
    {
      company: "Thompson Rivers University",
      title: "Career Ambassador",
      period: "September 2022 – April 2023",
      description:
        "As a member of the Career & Experiential Learning Department at TRU, I promote career services through tabling events and workshops. I collaborate with the team to enhance services and have volunteered for the TRU Job Fair and organized workshops like job market analysis, professional social shadow, and LinkedIn profile reviews.",
    },
    {
      company: "iTel Networks Inc.",
      title: "Full-Stack Developer Intern",
      period: "May 2022 – August 2022",
      description: "",
    },
    {
      company: "iTel Networks Inc.",
      title: "Full-Stack Developer Co-op",
      period: "May 2021 – April 2022",
      description:
        "I have extensive experience working on an internal company website utilizing React and Django while collaborating with a team using Git. In addition to my responsibilities in development, testing, code review, requirement refinement, and quality assurance while also being involved in website design and architecture. My expertise in agile development methodologies allows me to continuously improve my skills and stay updated with the latest technologies and industry practices. I was also responsible for writing reports on architecture and functionality, which have been submitted to government entities. Additionally, I have worked on hardware integration projects, utilizing third-party APIs to control and monitor hardware devices. I am passionate about delivering innovative and efficient solutions that exceed the expectations of clients and stakeholders.",
    },
    {
      company: "Thompson Rivers University",
      title: "Research Assitant",
      period: "March 2021 – May 2021",
      description:
        "As a Research Assistant to Dr. Piper Jackson at Thompson Rivers University, I worked on developing a Video Conferencing Mobile Application for the elderly community. The project aimed to facilitate communication among the elderly community. I conducted research on Video Conferencing Technology, identified different frameworks that would fit the purpose, and created a user-friendly interface for the target users. Additionally, I created low-fidelity and high-fidelity prototypes of the application to support the user experience enhancement journey.",
    },
  ];