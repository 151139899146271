import React from "react";
import "./ExperienceTimeline.css";
import { experiences } from "../Data/ExperiencesData";

const ExperienceTimeline = () => {
  return (
    <div className="experience-timeline">
      <div className="timeline">
        {experiences.map((position, posIndex) => (
          <div key={posIndex} className="experience position">
            <h2 className="company">{position.company}</h2>
            <h3 className="title">{position.title}</h3>
            <p className="period">{position.period}</p>
            <p className="description">{position.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceTimeline;
