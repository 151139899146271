// src/components/Profile.js
import React from 'react';
import './Profile.css';

const Profile = ({ profile }) => {
  return (
    <div className="profile">
      <div className="profile-container">
        <div className="profile-image">
          <img src={profile.image} alt="Profile" />
        </div>
        <div className="profile-info">
          <h1>{profile.name}</h1>
          <p>{profile.bio}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
