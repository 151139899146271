import GeneticDisorder from "../Visuals/ProjectImages/genetic_disorder.jpg";
import HandGesture from "../Visuals/ProjectImages/hand_gesture.jpg";
import Forest from "../Visuals/ProjectImages/forest_analysis.jpg";
import Quantum from "../Visuals/ProjectImages/quantum.jpg";
import BreastCancer from "../Visuals/ProjectImages/breast_cancer.jpg";
import covid from "../Visuals/ProjectImages/covid.jpg";
import Rental from "../Visuals/ProjectImages/rental_property.jpg";
import ToDo from "../Visuals/ProjectImages/to_do_tasks.jpg";
import Schedule from "../Visuals/ProjectImages/schedule_management.jpg";

export const projectsData = [
    {
      title: "Forest Analysis Using Drone Imagery",
      description: `As a team lead, collaborated with Aurora UAV to develop a LiDAR-based Forest analysis solution for accurate tree species identification using multispectral and RGB images. Conducted research, performed data analysis, fine-tuned algorithms, and created detailed documentation for the project. Led a team of 4 members, ensuring efficient collaboration and achieving project goals. Utilized an integrated R and Python pipeline for image processing and machine learning algorithms for tree segmentation. Delivered a comprehensive forest analysis tool. Throughout the project, we have been providing weekly progress reports and holding meetings with the client to ensure they are up to date on the project's progress. By doing so, we have been able to ensure that the project is progressing as planned and meet the client's expectations.`,
      imgSrc: Forest,
    },
    {
      title:
        "Feature Engineering for using American Sign Language for User Authentication",
      description: `As a part of my research work with a faculty member from the computer science department, I am currently leading a project that aims to detect if hand sign language can be used as a unique and reliable behavioral biometric for user authentication. The project involves primary data collection of videos of users making the same sign using American sign language, as there is limited prior research in this field. I am utilizing Recurrent Neural Networks (RNNs) to perform classification on the collected data and determine the uniqueness of the hand sign language. I am excited to contribute to this emerging field and look forward to publishing our findings.`,
      imgSrc: HandGesture,
    },
    {
      title: "Genetic Disorder Prediction Machine Learning Model",
      description: `I completed a project on predicting genetic disorders using machine learning models. I conducted data analysis and exploration, performed principal component analysis, and used a decision tree classifier to identify crucial features for classification. To test my hypothesis of achieving higher accuracy, I trained and optimized multi-layer perceptron and extra tree classifier models using cross-validation. I achieved an accuracy of 65% with MLP and 63% with the extra tree classifier. This project provided valuable insights into the importance of symptom features and genes in predicting genetic disorders and the potential of neural networks in the medical field.`,
      imgSrc: GeneticDisorder,
    },
    {
      title: "Research Report (Quantum Computing Operating System)",
      description: `Conducted extensive research on quantum computing and operating systems to understand the problems in managing quantum resources. Developed a research report that proposed a solution in the form of a quantum operating system model called Origin Pilot. The report analyzed the efficiency of the model and validated it through experimental executions and graphs. Contributed to the project by conducting experiments and writing sections of the report, including the introduction, summary, results, and general critique. Developed a moderate understanding of quantum computing and operating systems through the research and analysis conducted during the project.`,
      imgSrc: Quantum,
    },
    {
      title: "Breast Cancer Malignancy Detection Model",
      description: `As a Machine Learning Student, I conducted in-depth background research on breast cancer and factors affecting its malignancy. I leveraged the research results for feature engineering on patient records with breast cancer, identifying the most correlated features to enhance the precision of the predictive model. Through technical research, I determined the most suitable machine learning model and identified future development opportunities for the project. My efforts contributed to the development of a highly accurate breast cancer predictive model, providing valuable insights into the disease and potentially improving patient outcomes.`,
      imgSrc: BreastCancer,
    },
    {
      title: "COVID-19 Assessment Website Designing and Building",
      description: `As a part of my project work, I was responsible for incorporating user feedback and testing throughout the development process. I ensured that every phase of the project was well-documented, based on reliable sources. The documentation included a project proposal, requirement analysis, design analysis of the prototype, high-fidelity prototype instructions, manuals, and an evaluation document of data and analysis from the usability test. Through this project, I acquired skills in articulating and building project documentation, performing usability tests and pilot studies, and incorporating user feedback into the main project.`,
      imgSrc: covid,
    },
    {
      title: "Rental Property Management Application",
      description: `As a C++ Developer, I contributed to a 3-phase project that involved creating interface, implementation, and application files in each divided phase. Our team of 3 members worked collaboratively over Microsoft Teams and Visual Studio Live Share for conferencing and coding purposes. We ensured appropriate documentation to facilitate the development of later phases, with each phase's foundation dependent on the files from the previous phase. This experience allowed me to improve my collaborative skills, coding proficiency, and documentation capabilities.`,
      imgSrc: Rental,
    },
    {
      title: "To-Do Task Management Website",
      description: `I created a website with a primary focus on back-end development. The website allows users to create an account and manage their tasks using several editing options available. The website was developed based on the MVC Model using PHP and jQuery in JavaScript for interaction with the database. To facilitate communication with the database, we used JSON, AJAX, and MySQL. Although Bootstrap was used for subtle interface features, the website's focus was primarily on the back-end.`,
      imgSrc: ToDo,
    },
    {
      title: "Schedule Management Android Application",
      description: `I created a student-focused app for managing tasks and events with daily, weekly, and monthly views. The app allowed users to prioritize and delete events using a swipe feature and included an exam section that filtered events based on user-defined keywords. To store and manipulate data, I utilized the SQLite database and implemented the MVVM model, which facilitated the use of Live Data. Through this project, I gained valuable experience in Android app development and further honed my skills in database management and user interface design.`,
      imgSrc: Schedule,
    },
  ];