// src/components/Awards.js
import React from "react";
import "./Awards.css";
import { awardsData } from "../Data/AwardData";

const Awards = () => {
  return (
    <div className="awards">
      <h1 className="awards-title">Awards</h1>
      <ul>
        {awardsData.map((award, index) => (
          <li key={index}>
            <span className="award-symbol">{award.symbol}</span>
            <div className="award-content">
              <h2>{award.title}</h2>
              <p>{award.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Awards;
