// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Profile from "./components/Profile";
import Navbar from "./components/Navbar";
import Awards from "./components/Awards";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import ExperienceTimeline from "./components/ExperienceTimeline";
import { profileData } from "./Data/ProfileData";

function App() {
  const contact = {
    location: "Kamloops, British Columbia, Canada",
    linkedin: "https://www.linkedin.com/in/kulrajbir-singh-sandhu-a27b57191/",
    email: "kulrajsandhuu@gmail.com",
    phone: "+12502997205",
  };

  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={<Profile profile={profileData} />}
              index
            />
            <Route path="/awards" element={<Awards />} />
            <Route path="/contact" element={<Contact contact={contact} />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/experiences" element={<ExperienceTimeline />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
