import profilePicture from "../Visuals/TRU03544.jpg";

export const profileData = {
    image: profilePicture,
    name: "Kulrajbir Singh Sandhu",
    bio:
    "As a recent Computer Science graduate, I've transitioned into roles that align closely with my passion for advanced technology. At BCLC, my role as a Programmer Analyst involves tackling complex software development and analysis, enhancing both innovation and efficiency. Concurrently, I'm delving into deep learning in a research collaboration with Thompson Rivers University, exploring groundbreaking applications in Machine Learning and AI." +
    "My technical expertise spans various programming languages and development tools, enriched by my stint as a Full-stack Developer Intern at iTel Networks Inc. This background has equipped me with a holistic view of application development and a keen understanding of industry dynamics." +
    "In both my professional and research endeavors, I leverage my leadership and communication skills to foster environments of innovation and effective problem-solving. I'm committed to lifelong learning, eagerly embracing new challenges and opportunities for growth." +
    "As I navigate my career, I aim to not just excel in my roles but to be a visionary in the tech world, turning complex ideas into tangible, impactful solutions.",
  };