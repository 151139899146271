import React, { useState } from "react";
import "./Projects.css";
import { projectsData } from "../Data/ProjectData";

const ProjectCard = ({ project, onClick }) => (
  <div className="project-card" onClick={() => onClick(project)}>
    <img src={project.imgSrc} alt={project.title} />
    <h2 className="project-title">{project.title}</h2>
  </div>
);

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = (project) => {
    setSelectedProject(project);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <div className="projects">
      <div className="projects-container">
        {projectsData.map((project, index) => (
          <ProjectCard key={index} project={project} onClick={() => handleModalOpen(project)} />
        ))}
      </div>
      {modalVisible && (
        <div className="modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedProject.imgSrc} alt={selectedProject.title} />
            <h2 className="project-title">{selectedProject.title}</h2>
            <p className="project-description">{selectedProject.description}</p>
            <span className="close" onClick={handleModalClose}>&times;</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
