import React from 'react';
import './Contact.css';

const Contact = ({ contact }) => {
  return (
    <div className="contact-container">
      <div className="contact-content">
        <h2>Contact Information</h2>
        <div className="contact-info">
          <p>
            <i className="fas fa-map-marker-alt"></i> {contact.location}
          </p>
          <p>
            <i className="fab fa-linkedin"></i>{' '}
            <a href={contact.linkedin} target="_blank" rel="noreferrer">
              Linkedin
            </a>
          </p>
          <p>
            <i className="fas fa-envelope"></i> {contact.email}
          </p>
          <p>
            <i className="fas fa-phone"></i> {contact.phone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
